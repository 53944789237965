import { DataQueryFunction } from '../../models';
import { httpClient } from '../../index';
import { clearUrl } from '../../../common/utils';
import queryString from 'query-string';

interface IResponse {
  enUrl?: string;
}

export const queryErrorPage: DataQueryFunction<IResponse> = variables => {
  const { slug } = variables || {};

  return httpClient.get(clearUrl(`/locale/alternative`), {
    params: {
      url: slug,
    },
    paramsSerializer: params =>
      queryString.stringify(params, {
        encode: false,
      }),
  });
};
