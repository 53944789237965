import { DataQueryFunction } from 'network/models';
import { httpClient } from 'network';
import { clearUrl } from 'common/utils';
import { IPressKit } from '../../../features/contact/pages/models/presskit_page.props';

export const queryPressKitPage: DataQueryFunction<IPressKit> = variables => {
  const { locale } = variables || {};
  return httpClient.get(clearUrl(`/presskit`), {
    params: {
      lang: locale,
    },
  });
};
