import './HeaderMenu.scss';

import React from 'react';
import { useHistory } from 'react-router-dom';
import { PropsWithJsxAttributes } from '../../../utils/types/PropsWithJsxAttributes';
import { cn } from '../../../utils/bem';
import { useTheme } from '@dataartdev/uikit/Theme';
import { IconOpenInNew } from '@dataartdev/uikit/IconOpenInNew';
import { Button } from '@dataartdev/uikit/Button';
import { PortalWithTheme } from '@dataartdev/uikit/PortalWithTheme';
import { Picture } from '@dataartdev/uikit/Picture';
import TagManager from 'react-gtm-module';
import { Menu } from '../../../../store/models';

export const cnHeaderMenu = cn('HeaderMenu');
type Timeout = ReturnType<typeof setTimeout>;
type MenuProps = PropsWithJsxAttributes<
  {
    items: Menu[];
    hoverState: boolean;
    onHover?: () => void;
    onBlur?: () => void;
    onHeaderMenuHandler: () => void;
    container?: Element;
    inverted?: boolean;
  },
  'nav'
>;

export const HeaderMenu: React.FC<MenuProps> = ({
  items,
  className,
  onHover,
  onBlur,
  container = window.document.body,
  hoverState: hovered,
  inverted,
  onHeaderMenuHandler,
}) => {
  const { theme } = useTheme();
  const timer = React.useRef<Timeout | null>(null);
  const history = useHistory();

  const mouseEnterHandler = () => {
    if (onHover) onHover();
    if (timer.current) {
      clearTimeout(timer.current);
    }
  };

  const mouseLeaveHandler = () => {
    timer.current = setTimeout(() => {
      if (onBlur) onBlur();
    }, 500);
  };

  const setParams = ({
    title,
    nodeUrl,
    externalLink,
    targetBlank,
  }: {
    title?: string;
    nodeUrl?: string;
    externalLink?: string;
    targetBlank: boolean;
  }) => {
    return !!nodeUrl
      ? ({
          isLink: !!nodeUrl,
          as: !!nodeUrl ? 'link' : 'a',
          to: nodeUrl ?? '/404',
          target: targetBlank ? '_blank' : '_self',
          label: title ?? '',
          exact: true,
        } as const)
      : ({
          as: !!nodeUrl ? 'link' : 'a',
          href: externalLink,
          target: targetBlank ? '_blank' : '_self',
          label: title ?? '',
        } as const);
  };

  const servicesHandler = (title: string, nodeUrl?: string) => async () => {
    if (!nodeUrl?.includes('services')) return;
    onHeaderMenuHandler();
    const service = title.trim().toLowerCase();
    let dataLayer = {
      dataLayer: {
        event: 'click_services',
        services:
          service === 'services'
            ? 'click_all_services'
            : `click_${service.split(' ').join('_')}`,
      },
    };
    try {
      TagManager.dataLayer(dataLayer);

      if (nodeUrl.includes('http')) {
        window.location.href = nodeUrl;
      } else {
        history.push(nodeUrl);
      }
    } catch (error) {
      error instanceof Error
        ? console.error(error.message)
        : console.error('Error with TagManager dataLayer:', error);
    }
  };

  return (
    <nav className={cnHeaderMenu({ hovered }, [className])}>
      <ul className={cnHeaderMenu('List')}>
        {items.length > 0 &&
          items.map(
            ({
              type,
              node: { title, nodeUrl, externalLink, label, targetBlank },
              nodes = [],
              columns = [],
              sideColumn,
              // additionalNodes = [],
            }) => {
              const buttonAttrs = setParams({
                title,
                nodeUrl,
                externalLink,
                targetBlank,
              });
              return (
                <li
                  className={`HeaderMenu-Item ${(nodes?.length ||
                    columns?.length) &&
                    'HeaderMenu-Item_subItems'}`}
                  key={title}
                  onMouseEnter={() => mouseEnterHandler()}
                  onMouseLeave={() => mouseLeaveHandler()}
                >
                  <Button
                    inverted={inverted}
                    className={cnHeaderMenu('Link', {
                      inverted: !!label?.isInverted,
                    })}
                    view="transparent"
                    size="s"
                    {...buttonAttrs}
                    badge={
                      label && {
                        text: label.value,
                        inverted: !!label.isInverted,
                        color: label.labelColor,
                      }
                    }
                  />
                  {(nodes || columns) && (
                    <div className={cnHeaderMenu('SubList')}>
                      <div className={cnHeaderMenu('SubList-Inner')}>
                        {type === 0 && (
                          <div className={cnHeaderMenu('Container')}>
                            <div className={cnHeaderMenu('Columns')}>
                              <div className={cnHeaderMenu('Columns-Primary')}>
                                <div className={cnHeaderMenu('Column Wide')}>
                                  {nodes?.length > 0 &&
                                    nodes.map(
                                      ({
                                        externalLink,
                                        label,
                                        nodeUrl,
                                        targetBlank,
                                        title,
                                        // logo,
                                      }) => {
                                        const buttonAttrs = setParams({
                                          title,
                                          nodeUrl,
                                          externalLink,
                                          targetBlank,
                                        });
                                        return (
                                          <Button
                                            key={title}
                                            className={cnHeaderMenu('SubLink', {
                                              inverted: !!label?.isInverted,
                                            })}
                                            view="transparent"
                                            onClick={() =>
                                              onHeaderMenuHandler()
                                            }
                                            {...buttonAttrs}
                                            badge={
                                              label && {
                                                text: label.value,
                                                inverted: !!label.isInverted,
                                                color: label.labelColor,
                                              }
                                            }
                                          />
                                        );
                                      }
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {/*SERVICES*/}
                        {type === 1 && columns && (
                          <>
                            <div className={cnHeaderMenu('Extended')}>
                              {columns?.length > 0 &&
                                columns.map(column => {
                                  const {
                                    nodes = [],
                                    icon,
                                    bottomNode,
                                    // title,
                                  } = column || {};
                                  return (
                                    <div
                                      className={cnHeaderMenu('Extended-Col')}
                                    >
                                      {icon && (
                                        <Picture
                                          className={cnHeaderMenu('Preview')}
                                          {...icon}
                                        />
                                      )}
                                      <div className={cnHeaderMenu('LinkList')}>
                                        {nodes?.length > 0 &&
                                          nodes.map(node => {
                                            const {
                                              title,
                                              nodeUrl,
                                              externalLink,
                                              targetBlank,
                                              style,
                                              // logo,
                                              // label,
                                            } = node || {};
                                            const buttonAttrs = setParams({
                                              title,
                                              nodeUrl,
                                              externalLink,
                                              targetBlank,
                                            });
                                            return (
                                              <Button
                                                className={cnHeaderMenu(
                                                  'SubLink',
                                                  {
                                                    category:
                                                      style?.name === 'Bold',
                                                  }
                                                )}
                                                view="transparent"
                                                {...buttonAttrs}
                                                onClick={servicesHandler(
                                                  title,
                                                  nodeUrl
                                                )}
                                              />
                                            );
                                          })}
                                        {bottomNode && (
                                          <Button
                                            className={cnHeaderMenu('SubLink', {
                                              labs:
                                                bottomNode?.style?.name ===
                                                'Icon',
                                            })}
                                            view="transparent"
                                            as="a"
                                            href={bottomNode?.externalLink}
                                            onClick={() =>
                                              onHeaderMenuHandler()
                                            }
                                          >
                                            <Picture {...bottomNode.logo} />
                                          </Button>
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                              {sideColumn && sideColumn.nodes?.length > 0 && (
                                <div
                                  className={cnHeaderMenu('Extended-Col', {
                                    sideColumn: true,
                                  })}
                                >
                                  <div className={cnHeaderMenu('LinkList')}>
                                    {sideColumn.nodes.map((node, index) => {
                                      const {
                                        title,
                                        nodeUrl,
                                        targetBlank,
                                        logo,
                                        externalLink,
                                        style,
                                        // label,
                                      } = node || {};
                                      const buttonAttrs = setParams({
                                        title,
                                        nodeUrl,
                                        externalLink,
                                        targetBlank,
                                      });
                                      return index === 0 ? (
                                        <Button
                                          onClick={() => onHeaderMenuHandler()}
                                          className={cnHeaderMenu('SubLink', {
                                            category: style.name === 'Bold',
                                          })}
                                          view="transparent"
                                          {...buttonAttrs}
                                        />
                                      ) : (
                                        <Button
                                          onClick={() => onHeaderMenuHandler()}
                                          className={cnHeaderMenu('Partner')}
                                          view="transparent"
                                          iconRight={IconOpenInNew}
                                          iconLeft={() => <Picture {...logo} />}
                                          {...buttonAttrs}
                                        />
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </li>
              );
            }
          )}
      </ul>

      {hovered && (
        <PortalWithTheme
          preset={theme}
          container={container}
          className={cnHeaderMenu('Overlay')}
        >
          <div className={cnHeaderMenu('Backdrop')} aria-label="Overlay" />
        </PortalWithTheme>
      )}
    </nav>
  );
};
