import './FileCardForm.scss';

import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import * as yup from 'yup';
import { Message } from 'yup/lib/types';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { cn } from 'ui/utils/bem';
import { Button } from '@dataartdev/uikit/Button';
import { Checkbox } from '@dataartdev/uikit/Checkbox';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { TextField } from '@dataartdev/uikit/TextField';
import { BodyType } from '../ContentBuilder/ContentBuilder';
import { FormDownloadFileDictionary } from '../../../store/models';
import { useFormik } from 'formik';

yup.addMethod<yup.StringSchema>(yup.string, 'integer', function(msg) {
  return this.matches(/^\d+$/, msg || 'The field should have digits only');
});

export const schemaLetsTalk = (
  phoneError?: Message,
  emailError?: Message,
  requiredError?: Message
) => [
  yup.object({
    email: yup
      .string()
      .email(emailError)
      .required(requiredError)
      .nullable(),
    privacyAgreement: yup
      .boolean()
      .oneOf([true], 'on')
      .required(),
  }),

  yup.object({
    name: yup
      .string()
      .min(3)
      .required()
      .nullable(),
    email: yup
      .string()
      .email(emailError)
      .required(requiredError)
      .nullable(),
    privacyAgreement: yup
      .boolean()
      .oneOf([true], 'on')
      .required(),
  }),

  yup.object({
    name: yup
      .string()
      .min(3)
      .required()
      .nullable(),
    email: yup
      .string()
      .min(3)
      .email(emailError)
      .required(requiredError)
      .nullable(),
    phone: yup
      .string()
      .nullable()
      .integer(phoneError)
      .min(6)
      .max(20)
      .required(),
    company: yup
      .string()
      .min(3)
      .nullable(),
    additionalInfo: yup
      .string()
      .min(3)
      .nullable(),
    privacyAgreement: yup
      .boolean()
      .oneOf([true], 'on')
      .required(),
  }),

  yup.object({
    name: yup
      .string()
      .min(3)
      .required()
      .nullable(),
    email: yup
      .string()
      .email(emailError)
      .required(requiredError)
      .nullable(),
    company: yup
      .string()
      .min(3)
      .nullable(),
    privacyAgreement: yup
      .boolean()
      .oneOf([true], 'on')
      .required(),
  }),
];

interface Props {
  title?: string;
  extension?: string;
  date?: string;
  size?: number;
  type: number;
  dictionaries?: FormDownloadFileDictionary;
  recaptchaKey?: string;
  onSubmit: (body: BodyType) => Promise<void>;
}

const cnFileCardForm = cn('FileCardForm');

export const FileCardForm: React.FC<Props> = ({
  onSubmit,
  type = 0,
  dictionaries,
  recaptchaKey,
}) => {
  const initialValues = {
    name: '',
    phone: '',
    company: '',
    email: '',
    additionalInfo: '',
    privacyAgreement: false,
  };

  const reCaptchaRef = useRef<ReCAPTCHA>(null);
  const schema = schemaLetsTalk();

  const {
    handleSubmit,
    isSubmitting,
    errors,
    handleChange,
    handleBlur,
    values,
    touched,
    dirty,
  } = useFormik({
    initialValues,
    validationSchema: schema[type],
    onSubmit: async values => {
      let token = null;
      if (recaptchaKey) token = await reCaptchaRef.current?.executeAsync();
      await onSubmit({ ...values, token: token });
    },
  });

  const {
    companySiteFormDownloadFileSubmit = '',
    companySiteFormDownloadFileCompany = '',
    companySiteFormDownloadFilePhone = '',
    companySiteFormDownloadFileAddInfo = '',
    companySiteFormDownloadFileSubscribePolicy = '',
    companySiteFormDownloadFileEmail = '',
  } = dictionaries?.companySiteFormDownloadFile || {};

  return (
    <form className={cnFileCardForm({ type })} onSubmit={handleSubmit}>
      <div className={cnFileCardForm('Inner')}>
        <div className={cnFileCardForm('Body')}>
          <Grid
            breakpoints={{
              desktop: { cols: type === 2 ? 2 : 1, gap: 'xl' },
              tablet: { cols: 1, gap: 'xl' },
              mobile: { cols: 1, gap: 'xl' },
            }}
          >
            {(type === 1 || type === 2 || type === 3) && (
              <GridItem>
                <TextField
                  name="name"
                  required
                  onChange={({ e }) => handleChange(e)}
                  onBlur={handleBlur}
                  value={values.name}
                  label="Name"
                  view="outline"
                  width="full"
                  caption={
                    touched.name && errors.name ? errors.name : undefined
                  }
                  status={touched.name && !!errors.name ? 'alert' : undefined}
                />
              </GridItem>
            )}
            {type === 2 && (
              <>
                <GridItem>
                  <TextField
                    name="company"
                    onChange={({ e }) => handleChange(e)}
                    onBlur={handleBlur}
                    value={values.company}
                    label={companySiteFormDownloadFileCompany}
                    view="outline"
                    width="full"
                    type="tel"
                    caption={
                      touched.company && errors.company
                        ? errors.company
                        : undefined
                    }
                    status={
                      touched.company && !!errors.company ? 'alert' : undefined
                    }
                  />
                </GridItem>
                <GridItem>
                  <TextField
                    name="phone"
                    onChange={({ e }) => handleChange(e)}
                    onBlur={handleBlur}
                    value={values.phone}
                    label={companySiteFormDownloadFilePhone}
                    view="outline"
                    width="full"
                    type="tel"
                    required
                    caption={
                      touched.phone && errors.phone ? errors.phone : undefined
                    }
                    status={
                      touched.phone && !!errors.phone ? 'alert' : undefined
                    }
                  />
                </GridItem>
              </>
            )}
            <GridItem>
              <TextField
                name="email"
                onChange={({ e }) => handleChange(e)}
                onBlur={handleBlur}
                value={values.email}
                label={companySiteFormDownloadFileEmail}
                view="outline"
                width="full"
                required
                caption={
                  touched.email && errors.email ? errors.email : undefined
                }
                status={touched.email && !!errors.email ? 'alert' : undefined}
              />
            </GridItem>
            {type === 3 && (
              <GridItem>
                <TextField
                  name="company"
                  onChange={({ e }) => handleChange(e)}
                  onBlur={handleBlur}
                  value={values.company}
                  label={companySiteFormDownloadFileCompany}
                  view="outline"
                  width="full"
                  type="tel"
                  caption={
                    touched.company && errors.company
                      ? errors.company
                      : undefined
                  }
                  status={
                    touched.company && !!errors.company ? 'alert' : undefined
                  }
                />
              </GridItem>
            )}
            {type === 2 && (
              <GridItem>
                <TextField
                  name="additionalInfo"
                  onChange={({ e }) => handleChange(e)}
                  onBlur={handleBlur}
                  value={values.additionalInfo}
                  label={companySiteFormDownloadFileAddInfo}
                  view="outline"
                  width="full"
                  caption={
                    touched.additionalInfo && errors.additionalInfo
                      ? errors.additionalInfo
                      : undefined
                  }
                  status={
                    touched.additionalInfo && !!errors.additionalInfo
                      ? 'alert'
                      : undefined
                  }
                />
              </GridItem>
            )}
            <GridItem className={cnFileCardForm('Buttons')}>
              <div className={cnFileCardForm('Bottom')}>
                <Checkbox
                  required
                  label={companySiteFormDownloadFileSubscribePolicy}
                  name="privacyAgreement"
                  onChange={({ e }) => handleChange(e)}
                  onBlur={handleBlur}
                  checked={values.privacyAgreement}
                />
                <Button
                  iconRight={IconArrowRight}
                  disabled={
                    isSubmitting || !dirty || !!Object.keys(errors).length
                  }
                  label={companySiteFormDownloadFileSubmit}
                  type="submit"
                />
              </div>
            </GridItem>
          </Grid>
        </div>

        {recaptchaKey && (
          <ReCAPTCHA
            ref={reCaptchaRef}
            sitekey={recaptchaKey}
            size="invisible"
          />
        )}
      </div>
    </form>
  );
};
