import './HeaderMenuMobile.scss';

import React, { useState } from 'react';
import { generatePath, RouteComponentProps } from 'react-router-dom';
import { PropsWithJsxAttributes } from '../../../utils/types/PropsWithJsxAttributes';
import { cn } from '../../../utils/bem';
import { IconArrowDown } from '@dataartdev/uikit/IconArrowDown';
import { IconOpenInNew } from '@dataartdev/uikit/IconOpenInNew';
import { Button } from '@dataartdev/uikit/Button';
import { Typography } from '@dataartdev/uikit/Typography';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { Picture } from '@dataartdev/uikit/Picture';
import { Language, Menu } from '../../../../store/models';

export const cnHeaderMenuMobile = cn('HeaderMenuMobile');

type MenuProps = Pick<RouteComponentProps, 'match'> &
  PropsWithJsxAttributes<
    {
      items: Menu[];
      language?: Language;
      languages?: Language[];
    },
    'nav'
  >;

export const HeaderMenuMobile: React.FC<MenuProps> = ({
  items,
  className,
  onClick,
  match,
  language,
  languages = [],
}) => {
  const setParams = ({
    title,
    nodeUrl,
    externalLink,
    targetBlank,
  }: {
    title?: string;
    nodeUrl?: string;
    externalLink?: string;
    targetBlank: boolean;
  }) => {
    return !!nodeUrl
      ? ({
          onClick,
          width: 'full',
          isLink: !!nodeUrl,
          as: !!nodeUrl ? 'link' : 'a',
          to: nodeUrl ?? '/404',
          target: targetBlank ? '_blank' : '_self',
          label: title ?? '',
          exact: true,
        } as const)
      : ({
          onClick,
          width: 'full',
          as: !!nodeUrl ? 'link' : 'a',
          href: externalLink,
          target: targetBlank ? '_blank' : '_self',
          label: title ?? '',
        } as const);
  };

  const mainMenuItems =
    items?.filter(item => !item.node?.nodeUrl?.includes('/contact-us')) ?? [];

  const contactUsItem = items?.find(item =>
    item.node?.nodeUrl?.includes('/contact-us')
  );

  const [openMenu, setOpenMenu] = useState<string | null>(null);

  const toggleMenu = (title: string) => {
    setOpenMenu(prev => (prev === title ? null : title));
  };

  return (
    <nav className={cnHeaderMenuMobile(null, [className])}>
      <div className={cnHeaderMenuMobile('Main')}>
        <div className={cnHeaderMenuMobile('List')}>
          {mainMenuItems.map(item => {
            if (item.type === 1) {
              const {
                node,
                columns = [],
                sideColumn,
                // nodes,
                // additionalNodes
              } = item || {};
              const { title } = node || {};
              const hasChildren = !!(
                columns.length > 0 ||
                (sideColumn && sideColumn?.nodes?.length > 0)
              );
              return (
                <div
                  className={cnHeaderMenuMobile('Menu-Item')}
                  key={item.node.title}
                >
                  <Button
                    className={cnHeaderMenuMobile('Link', {
                      primary: true,
                      active: openMenu === title,
                      hasChildren: columns.length > 0,
                      // open: fixedMenu === title,
                    })}
                    view="transparent"
                    align="left"
                    iconRight={IconArrowDown}
                    label={title}
                    onClick={() => toggleMenu(title)}
                  />
                  <div
                    className={cnHeaderMenuMobile('Sub-List', {
                      visible: openMenu === title,
                    })}
                  >
                    {hasChildren &&
                      [...columns, sideColumn].map(column => {
                        const { nodes = [], bottomNode } = column || {};
                        return (
                          <React.Fragment key={node.nodeUrl}>
                            {nodes.map(node => {
                              return (
                                <Button
                                  key={node.title}
                                  className={cnHeaderMenuMobile('Link', {
                                    secondary: node.style.name === 'Bold',
                                    tertiary: node.style.name === 'None',
                                    all: node.style.name === 'Thin',
                                    withLogo: !!node.logo,
                                  })}
                                  view="transparent"
                                  align="left"
                                  iconRight={
                                    !!node.logo ? IconOpenInNew : undefined
                                  }
                                  iconLeft={() =>
                                    !!node.logo ? (
                                      <Picture {...node.logo} />
                                    ) : null
                                  }
                                  {...setParams({
                                    title: node.title,
                                    nodeUrl: node.nodeUrl,
                                    externalLink: node.externalLink,
                                    targetBlank: node.targetBlank,
                                  })}
                                />
                              );
                            })}
                            {bottomNode && bottomNode.logo && (
                              <Button
                                className={cnHeaderMenuMobile('Link', {
                                  secondary: bottomNode.style.name === 'Bold',
                                  isImage: !!bottomNode.logo,
                                })}
                                view="transparent"
                                align="left"
                                as="a"
                                href={bottomNode.externalLink}
                              >
                                <Picture {...bottomNode.logo} />
                              </Button>
                            )}
                          </React.Fragment>
                        );
                      })}
                  </div>
                </div>
              );
            }
            if (item.type === 0) {
              const { nodes = [], node } = item || {};
              return (
                <div className={cnHeaderMenuMobile('Menu-Item')}>
                  <Button
                    className={cnHeaderMenuMobile('Link', {
                      primary: node.style.name === 'Bold',
                      active: openMenu === node.title,
                      hasChildren: nodes.length > 0,
                      // open: fixedMenu === node.title,
                    })}
                    view="transparent"
                    align="left"
                    iconRight={nodes.length > 0 ? IconArrowDown : undefined}
                    label={node.title}
                    onClick={() => toggleMenu(node.title)}
                    {...(nodes.length === 0 &&
                      setParams({
                        title: node.title,
                        nodeUrl: node.nodeUrl,
                        externalLink: node.externalLink,
                        targetBlank: node.targetBlank,
                      }))}
                  />
                  <div
                    className={cnHeaderMenuMobile('Sub-List', {
                      visible: openMenu === node.title,
                    })}
                  >
                    {nodes.length > 0 &&
                      nodes.map(node => {
                        return (
                          <Button
                            className={cnHeaderMenuMobile('Link', {
                              secondary: node.style.name === 'None',
                            })}
                            view="transparent"
                            align="left"
                            {...setParams({
                              title: node.title,
                              nodeUrl: node.nodeUrl,
                              externalLink: node.externalLink,
                              targetBlank: node.targetBlank,
                            })}
                          />
                        );
                      })}
                  </div>
                </div>
              );
            }
          })}
        </div>
        {contactUsItem && (
          <div className={cnHeaderMenuMobile('Cta')}>
            <Button
              // className={cnHeaderMenuMobile('Link', {})}
              size="m"
              view="secondary"
              iconRight={IconArrowRight}
              {...setParams({ ...contactUsItem.node })}
            />
          </div>
        )}
      </div>
      <div className={cnHeaderMenuMobile('Languages')}>
        {languages?.map(lang => {
          const isActive = language?.slug === lang.slug;
          let redirectPath: string;
          if (!lang.slug) {
            redirectPath = generatePath(`/${match.path.substring(18)}`, {
              ...match.params,
              lang: lang.slug,
            });
          } else {
            redirectPath = generatePath(match.path, {
              ...match.params,
              lang: lang.slug,
            });
          }
          return (
            <a
              href={redirectPath}
              className={cnHeaderMenuMobile('Languages-Item', {
                Active: isActive,
              })}
              key={lang.slug}
            >
              <Typography.Text
                size="xs"
                weight="bold"
                transform="uppercase"
                onClick={() => localStorage.setItem('currentLang', lang.slug)}
              >
                {lang.slug === '' ? 'EN' : lang.slug.toUpperCase()}
              </Typography.Text>
            </a>
          );
        })}
      </div>
    </nav>
  );
};
